@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
* {
  padding: 0 0;
  margin: 0 0;
  box-sizing: border-box;
}

#root {
  overflow-x: hidden;
}

.css-vubbuv {
  margin-right: 10px;
}

.App {
  width: 100vw;
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;
}
.App .systemx {
  margin-bottom: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: -moz-max-content;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.App .systemx p {
  color: #000;
  text-align: center;
  font-family: "Figtree", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 72.8px */
}
.App .systemx img {
  width: 40%;
}
.App .one {
  height: 900px;
  position: relative;
  width: 100%;
  max-width: 1440px;
}
.App .one img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.App .one div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.78);
}
.App .one div header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5%;
}
.App .one div header .hamburger {
  display: none;
  z-index: 3;
  margin-right: 3%;
  cursor: pointer;
  width: 30px;
  opacity: 1;
  height: 40px;
  background-color: inherit;
  border-radius: 12.25px;
}
.App .one div header .hamburger #nav-icon4 {
  width: 60px;
  height: 35px;
  background-color: inherit;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  transform: scale(0.5);
  cursor: pointer;
}
.App .one div header .hamburger #nav-icon4 span {
  pointer-events: none;
}
.App .one div header .hamburger #nav-icon4 span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background-color: white;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.App .one div header .hamburger #nav-icon4 span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}
.App .one div header .hamburger #nav-icon4 span:nth-child(2) {
  top: 18px;
  transform-origin: left center;
}
.App .one div header .hamburger #nav-icon4 span:nth-child(3) {
  top: 36px;
  transform-origin: left center;
}
.App .one div header .hamburger #nav-icon4.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}
.App .one div header .hamburger #nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.App .one div header .hamburger #nav-icon4.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}
.App .one div header .hamburger:hover {
  opacity: 0.65;
}
.App .one div header img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 84px;
  height: 67px;
}
.App .one div header nav {
  display: flex;
  justify-content: space-between;
  width: 69%;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.App .one div header nav section {
  border-radius: 8px;
  justify-content: space-between;
  width: 70%;
  max-width: 530px;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(34px);
          backdrop-filter: blur(34px);
  display: flex;
  color: white;
  padding: 20px 16px;
}
.App .one div header nav section a {
  cursor: pointer;
  position: relative;
}
.App .one div header nav section a::after {
  position: absolute;
  border-top: 0.15em solid #e8c715;
  border-radius: 1rem;
  content: "";
  right: 100%;
  left: 0;
  bottom: -0.46em;
  transition: right 0.25s ease;
}
.App .one div header nav section a:hover::after {
  right: 40%;
}
.App .one div header nav .contact_us {
  border-radius: 8px;
  border: 1px solid #e8c715;
  background: #e8c715;
  padding: 16px 32px;
  transition: all 0.2s linear;
  cursor: pointer;
}
.App .one div header nav .contact_us:hover {
  background: #f0c654;
}
.App .one div article {
  margin-top: 175px;
  font-family: "Raleway", sans-serif;
}
.App .one div article h2 {
  font-size: 64px;
  font-weight: 800;
  margin-bottom: 30px;
  text-align: center;
  max-width: 1040px;
  margin: auto;
  opacity: 0;
  animation: pop 1s linear 1s 1 forwards;
  letter-spacing: -0.65px;
  color: white;
}
.App .one div article h2 span {
  color: #e8c715;
}
.App .one div article p {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}
.App .one div article p {
  color: white;
  margin: 10px 0 0 0;
  font-size: 19px;
  opacity: 1;
  letter-spacing: -0.35px;
  text-align: center;
  overflow-y: hidden;
  height: 3.5vh;
  margin: 30px auto 60px;
  font-family: "Raleway", sans-serif;
  max-width: 800px;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.App .one div article p span {
  margin-right: 5px;
  opacity: 0;
  filter: blur(4px);
}
.App .one div article p :nth-child(1) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(2) {
  animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(3) {
  animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(4) {
  animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(5) {
  animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(6) {
  animation: fade-in 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(7) {
  animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(8) {
  animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(9) {
  animation: fade-in 0.8s 2.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(10) {
  animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article p :nth-child(11) {
  animation: fade-in 0.8s 3.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.App .one div article .button {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  color: black;
  text-decoration: none;
  margin-top: 40px;
  font-family: "Raleway", sans-serif;
  background: #e8c715;
  padding: 16px 32px;
  line-height: 145%; /* 29px */
  transition: all 0.2s linear;
  border: none;
  opacity: 0;
  animation: pop 1s linear 1s 1 forwards;
  cursor: pointer;
}
.App .one div article .button:hover {
  background: #f0c654;
}
.App .two,
.App .three,
.App .four {
  text-align: center;
  padding-top: 120px;
  margin: 0 55px;
  position: relative;
  right: 8px;
}
.App .two .head,
.App .three .head,
.App .four .head {
  color: #403a24;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 400;
  border-radius: 16px;
  background: #f7e08c;
  margin-bottom: 20px;
  padding: 10px 20px;
  line-height: 145%; /* 29px */
}
.App .two h2,
.App .three h2,
.App .four h2 {
  color: #000;
  text-align: center;
  font-family: "Figtree", sans-serif;
  font-size: 56px;
  font-style: normal;
  margin-top: 30px;
  font-weight: 700;
  line-height: 130%; /* 72.8px */
}
.App .two .sub_text,
.App .three .sub_text,
.App .four .sub_text {
  color: #6b6b6b;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 29px */
}
.App .two section,
.App .three section,
.App .four section {
  margin: 88px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  gap: 10px;
}
.App .two section div,
.App .three section div,
.App .four section div {
  position: relative;
  height: 500px;
}
.App .two section div img,
.App .three section div img,
.App .four section div img {
  -o-object-fit: cover;
     object-fit: cover;
  width: -webkit-fill-available;
  height: 500px;
  position: relative;
}
.App .two section div p,
.App .three section div p,
.App .four section div p {
  position: absolute;
  bottom: 50px;
  color: white;
  font-size: 36px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  z-index: 3;
  text-align: center;
  width: -webkit-fill-available;
}
.App .two section div::before,
.App .three section div::before,
.App .four section div::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  content: "";
  background: rgba(0, 0, 0, 0.3);
}
.App .two .mobile,
.App .three .mobile,
.App .four .mobile {
  display: none;
}
.App .two .info,
.App .three .info,
.App .four .info {
  margin-bottom: 83px;
  font-family: "Figtree", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.App .two .info span,
.App .three .info span,
.App .four .info span {
  color: black;
  margin-right: 5px;
}
.App .two .info a,
.App .three .info a,
.App .four .info a {
  color: #e0bf0b;
  cursor: pointer;
  transition: opacity 0.3s linear;
}
.App .two .info a:hover,
.App .three .info a:hover,
.App .four .info a:hover {
  opacity: 0.56;
}
.App .three {
  padding-top: 0;
  width: 85%;
  margin: auto;
}
.App .three article {
  display: grid;
  margin: 80px 0;
  gap: 18px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.App .three article .comments {
  background: #f2f2f2;
  padding: 15px 15px 50px;
  border-radius: 8px;
  position: relative;
}
.App .three article .comments div {
  display: flex;
  justify-content: space-between;
}
.App .three article .comments div p span {
  margin-right: 4px;
}
.App .three article .comments div p span .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
  color: orange;
}
.App .three article .comments div img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 18px;
  height: 18px;
}
.App .three article .comments .text {
  margin: 25px 0;
  text-align: left;
  font-size: 16px;
  color: #6b6b6b;
  line-height: 145%;
  font-family: "Raleway", sans-serif;
}
.App .three article .comments .name {
  position: absolute;
  bottom: 40px;
  left: 15px;
  font-family: "Raleway", sans-serif;
}
.App .form {
  position: relative;
  background: #fffdf2;
  padding: 100px 0px;
}
.App .form .right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 180px;
}
.App .form .left {
  position: absolute;
  width: 190px;
  bottom: 0px;
  left: 0px;
}
.App .form .parent {
  background-color: white;
  position: relative;
  margin: auto;
  width: 85%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.App .form .parent article {
  padding: 48px 34px;
}
.App .form .parent article h1 {
  font-family: "Figtree", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 72.8px */
}
.App .form .parent article p {
  max-width: 460px;
  font-family: "Raleway", sans-serif;
  color: #6b6b6b;
}
.App .form .parent article a {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: black;
  margin: 20px 0;
  display: flex;
  align-items: center;
  line-height: 145%; /* 29px */
}
.App .form .parent article a span {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-style: normal;
  text-decoration: underline;
  margin-left: 5px;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  text-decoration-line: underline;
}
.App .form .parent article a img {
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
     object-fit: contain;
}
.App .form .parent article h5 {
  margin-top: 40px;
  color: #000;
  font-family: "Figtree", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 41.6px */
}
.App .form .parent aside {
  width: 40%;
  height: -webkit-fill-available;
  margin-right: 38px;
}
.App .form .parent aside div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
}
.App .form .parent aside div section {
  position: absolute;
  display: none;
  background-color: #f8f8f8;
  z-index: 2;
  width: -webkit-fill-available;
  bottom: -212px;
  border-radius: 5px;
  left: 0;
}
.App .form .parent aside div section p {
  margin: 12px 0px;
  padding-left: 16px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-style: normal;
  border: none;
  cursor: pointer;
  transition: all 0.2s linear;
}
.App .form .parent aside div section p:hover {
  opacity: 0.55;
}
.App .form .parent aside div span {
  color: #000;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-style: normal;
  margin: 5px 0;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
}
.App .form .parent aside div input,
.App .form .parent aside div .services {
  outline: none;
  background-color: #f2f2f2;
  height: 48px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-style: normal;
  border: none;
  padding: 0 16px;
}
.App .form .parent aside div .services {
  display: flex;
  padding: 0px 16px 0px 0px;
  align-items: center;
  justify-content: space-between;
}
.App .form .parent aside div .services .arrow {
  transition: all 0.2s linear;
  will-change: transform;
  -webkit-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  cursor: pointer;
}
.App .form .parent aside div .services .arrow:hover {
  opacity: 0.57;
}
.App .form .parent aside div .services .arrow .css-i4bv87-MuiSvgIcon-root {
  pointer-events: none;
}
.App .form .parent aside div #message {
  height: 160px;
  outline: none;
  background-color: #f2f2f2;
  resize: none;
  padding: 12px 16px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-style: normal;
  border: none;
}
.App .form .parent aside button {
  margin-top: 28px;
  margin-bottom: 22px;
  color: black;
  width: -webkit-fill-available;
  background: #e8c715;
  padding: 12px 0px;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.4s linear;
  border: none;
  cursor: pointer;
}
.App .form .parent aside button:hover {
  background: #f0c654;
  color: rgb(87, 87, 87);
}
.App .sub {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 85%;
  margin: auto;
  padding: 100px 0px;
  justify-content: space-between;
}
.App .sub aside h4 {
  color: var(--primary-black, #141414);
  font-family: "Figtree", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 32px;
  line-height: 130%; /* 72.8px */
}
.App .sub aside p {
  color: #000;
  font-family: "Raleway", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  max-width: 530px;
  line-height: 145%; /* 29px */
}
.App .sub img {
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 600px;
  min-width: 300px;
  width: 45%;
  flex: 1;
}
.App footer {
  background-color: #171506;
}
.App footer a {
  color: black;
  display: flex;
  text-decoration-color: #736928;
}
.App footer div {
  display: flex;
}
.App footer .main {
  padding: 95px 60px 70px;
}
.App footer .main section {
  display: flex;
  margin-left: 80px;
  width: 70%;
  justify-content: space-between;
  min-width: 800px;
  align-items: flex-start;
}
.App footer .main section div {
  display: flex;
  flex-direction: column;
}
.App footer .main section div h6 {
  font-size: 20px;
  color: #99891E;
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.App footer .main section div .touch {
  position: relative;
  bottom: 10px;
}
.App footer .main section div p {
  max-width: 300px;
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
  display: flex;
  color: #736928;
}
.App footer .main section div span {
  margin-bottom: 6px;
  white-space: nowrap;
  font-family: "Raleway", sans-serif;
  color: #736928;
}
.App footer .main section div .css-i4bv87-MuiSvgIcon-root {
  color: white;
  margin-right: 10px;
}
.App footer .main section div .css-vubbuv {
  margin-right: 10px;
  color: white;
}
.App footer .sub_p {
  justify-content: space-between;
}
.App footer .sub_p img {
  width: 38%;
}
.App footer .sub_p nav {
  white-space: nowrap;
  display: flex;
}
.App footer .sub_p nav a {
  margin-right: 10px;
}
.App footer .sub_p nav a img {
  width: -moz-fit-content;
  width: fit-content;
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes pop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 1065px) {
  .App .sub {
    justify-content: center;
    padding: 50px;
  }
  .App .sub aside {
    margin-bottom: 42px;
  }
  .App .sub aside h4 {
    text-align: center;
  }
  .App .sub aside p {
    max-width: -webkit-fill-available;
    text-align: center;
  }
  .App footer .main {
    flex-direction: column;
  }
  .App footer .main .logo {
    width: 100px;
    height: 140px;
  }
  .App footer .main section {
    flex-direction: column;
    margin-left: 0px;
  }
  .App footer .main section div p {
    max-width: 500px;
  }
  .App footer .main section h6 {
    margin-top: 20px;
  }
}
@media screen and (max-width: 900px) {
  .App .systemx {
    margin-bottom: 60px;
    flex-direction: column;
  }
  .App .systemx p {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .App .systemx img {
    width: 50%;
  }
  .App .one {
    height: 650px;
  }
  .App .one div article {
    margin-top: 100px;
  }
  .App .one div article h2 {
    font-size: 48px;
    max-width: 550px;
  }
  .App .one div article p {
    font-size: 16px;
    margin: 15px auto 50px;
  }
  .App .one div article .button {
    font-size: 18px;
  }
  .App .one div header {
    position: relative;
  }
  .App .one div header .hamburger {
    display: block;
  }
  .App .one div header nav {
    display: none;
    z-index: 1;
    flex-direction: column;
    position: absolute;
    right: 0;
    padding: 50px 0;
    width: 40vw;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    top: 0;
    transition: all 0.3s linear;
  }
  .App .one div header nav section {
    flex-direction: column;
    min-width: 200px;
    background: inherit;
    width: 40vw;
  }
  .App .one div header nav section a {
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 35px;
  }
  .App .two,
  .App .three,
  .App .four {
    padding-top: 20px;
  }
  .App .two .head,
  .App .three .head,
  .App .four .head {
    font-size: 18px;
    padding: 8px 16px;
  }
  .App .two .info,
  .App .three .info,
  .App .four .info {
    margin-bottom: 50px;
  }
  .App .two h2,
  .App .three h2,
  .App .four h2 {
    font-size: 40px;
    margin-top: 20px;
  }
  .App .two .sub_text,
  .App .three .sub_text,
  .App .four .sub_text {
    font-size: 18px;
  }
  .App .two section,
  .App .three section,
  .App .four section {
    margin: 50px 0;
  }
  .App .two section div,
  .App .three section div,
  .App .four section div {
    height: 300px;
  }
  .App .two section div img,
  .App .three section div img,
  .App .four section div img {
    height: 300px;
  }
  .App .two section div p,
  .App .three section div p,
  .App .four section div p {
    font-size: 24px;
  }
  .App .three article {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .App .form {
    padding: 50px 0;
  }
  .App .form .parent {
    flex-direction: column;
    align-items: flex-start;
  }
  .App .form .parent article a,
  .App .form .parent article p {
    max-width: -webkit-fill-available;
    font-size: 18px;
  }
  .App .form .parent article h1 {
    font-size: 40px;
  }
  .App .form .parent article h5 {
    font-size: 23px;
    margin-top: 25px;
  }
  .App .form .parent aside {
    width: -webkit-fill-available;
    margin: 0 32px;
    margin-bottom: 48px;
  }
  .App .sub aside h4 {
    font-size: 40px;
    margin-bottom: 12px;
  }
  .App .sub aside p {
    font-size: 18px;
  }
  .App .sub img {
    width: -webkit-fill-available;
  }
  .App footer .main {
    padding: 40px;
  }
  .App footer .main section div .touch {
    bottom: 0px;
  }
}
@media screen and (max-width: 500px) {
  .App .systemx {
    margin: 40px auto 70px;
  }
  .App .systemx p {
    font-size: 20px;
  }
  .App .systemx img {
    width: 43%;
  }
  .App .one {
    height: 550px;
  }
  .App .one div header nav {
    width: 55vw;
    height: 100vh;
    border-radius: 0px;
    z-index: 2;
    min-width: 200px;
    justify-content: flex-start;
    align-items: baseline;
    background: rgba(0, 0, 0, 0.75);
  }
  .App .one div header nav section {
    background: none;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }
  .App .one div header nav section a {
    font-size: 16px;
  }
  .App .one div header nav .contact_us {
    font-size: 16px;
    padding: 8px 20px;
    margin-left: 16px;
  }
  .App .one div header .hamburger {
    margin-right: 5.5vw;
  }
  .App .one div header img {
    width: 64px;
    height: 47px;
    margin-top: 10px;
  }
  .App .one div article h2 {
    font-size: 30px;
    max-width: 300px;
    line-height: 40px;
  }
  .App .one div article p {
    max-width: 300px;
    overflow: visible;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 50px;
    word-wrap: break-word;
  }
  .App .one div article .button {
    font-size: 16px;
    margin-top: 20px;
    padding: 8px 16px;
  }
  .App .two,
  .App .three,
  .App .four {
    padding-top: 40px;
    margin: 0 10px;
  }
  .App .two .head,
  .App .three .head,
  .App .four .head {
    font-size: 16px;
    margin-bottom: 10px;
    padding: 5px 10px;
  }
  .App .two h2,
  .App .three h2,
  .App .four h2 {
    font-size: 32px;
    margin-top: 15px;
  }
  .App .two .sub_text,
  .App .three .sub_text,
  .App .four .sub_text {
    font-size: 16px;
  }
  .App .two section,
  .App .three section,
  .App .four section {
    margin: 25px 0.3vw;
    height: 300px;
  }
  .App .two .desktop,
  .App .three .desktop,
  .App .four .desktop {
    display: none;
  }
  .App .two .mobile,
  .App .three .mobile,
  .App .four .mobile {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    margin: 30px 0.2vw;
    width: 100vw;
    max-width: 1440px;
    overflow: hidden;
  }
  .App .two .mobile section,
  .App .three .mobile section,
  .App .four .mobile section {
    display: flex;
    animation: h-scroll 11s linear infinite;
    will-change: transform;
    margin: 0;
    padding: 0 5px;
  }
  .App .two .mobile section div,
  .App .three .mobile section div,
  .App .four .mobile section div {
    width: 246px;
  }
  .App .two .mobile section div img,
  .App .three .mobile section div img,
  .App .four .mobile section div img {
    width: 246px;
  }
  .App .two .info,
  .App .three .info,
  .App .four .info {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .App .two .mobile::before,
  .App .three .mobile::before,
  .App .four .mobile::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    z-index: 2;
    content: "";
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(33, 32, 32));
  }
  .App .two .mobile::after,
  .App .three .mobile::after,
  .App .four .mobile::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100%;
    z-index: 2;
    content: "";
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(33, 32, 32));
  }
  .App .three {
    width: 100%;
    padding: 0 20px;
    margin-top: 60px;
  }
  .App .three .sub_text {
    padding-top: 15px;
  }
  .App .three .head {
    margin-top: 15px;
  }
  .App .three h2 {
    margin-top: 20px;
  }
  .App .three article {
    display: flex;
    width: 85%;
    overflow: hidden;
    min-height: 360px;
    margin: 40px auto;
  }
  .App .three article .comments {
    opacity: 0;
    transition: opacity 1s linear;
    width: 100%;
  }
  .App .three article .active {
    opacity: 1;
  }
  .App .sub {
    right: 10px;
    margin-top: 40px;
    padding: 0px;
  }
  .App .sub aside h4 {
    font-size: 32px;
  }
  .App .sub aside p {
    font-size: 16px;
  }
  .App .form .right,
  .App .form .left {
    width: 120px;
  }
  .App .form .parent article {
    padding: 48px 24px;
  }
  .App .form .parent article h1 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 7px;
  }
  .App .form .parent article a,
  .App .form .parent article p {
    font-size: 16px;
    text-align: center;
  }
  .App .form .parent article h5 {
    font-size: 20px;
  }
  .App .form .parent article button {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .App .form .parent aside {
    margin: 0 22px;
  }
  .App .form .parent aside button {
    font-size: 18px;
  }
  .App footer .main {
    padding: 0px 18px 30px;
  }
  .App footer .main section div #about {
    margin-top: 0px;
  }
  .App footer .main section div p {
    max-width: 300px;
  }
  .App footer .main section div p .MuiSvgIcon-root {
    color: white;
    margin-right: 9px;
  }
  .App footer .main section div a .MuiSvgIcon-root {
    color: white;
    margin-right: 9px;
  }
}
@keyframes h-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}/*# sourceMappingURL=App.css.map */