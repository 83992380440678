#service_area {
  display: block;
  margin-bottom: 130px;
  .sub_text {
    margin-bottom: 96px;
  }
  .big {
    color: #000;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    font-style: normal;
    margin-top: 32px;
    font-weight: 500;
    line-height: 145%; /* 29px */
  }
  .little {
    color: #1a1a1a;
    text-align: center;
    font-family: Raleway;
    margin: 15px auto 55px auto;
    font-size: 20px;
    max-width: 850px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 29px */
  }
  .button {
    border-radius: 8px;
    background: #e8c715;
    padding: 16px 32px;
    text-decoration: none;
    color: #141102;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 29px */
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover {
      background: #f0c654;
    }
  }
  .solo {
    margin-top: 64px;
    h5 {
      font-family: "Figtree", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 41.6px */
    }
    .showroom {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
      grid-gap: 1.5rem;
      grid-row-gap: 1.5rem;
      row-gap: 1.5rem;
      grid-column-gap: 1.5rem;
      gap: 10px;
      padding-top: 32px;
      div {
        height: 162px;
        position: relative;
        img {
          object-fit: contain;
          border-radius: 10px;
          width: 100%;
          height: 162px;
          object-fit: cover;
        }
        p {
          position: absolute;
          top: 40%;
          padding-left: 10px;
          color: white;
          z-index: 2;
          font-family: "Raleway", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 145%; /* 29px */
        }
      }
      div::before {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        content: "";
        background: rgba(0, 0, 0, 0.5);
      }
    }
    .image-slider {
      position: relative;
      width: 88%;
      height: 262px;
      overflow: hidden;
      margin: auto;
      #slides {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 162px;
        object-fit: cover;
        transition: opacity 1s ease-in-out;
        img {
          width: 100%;
          height: 262px;
          object-fit: cover;
          border-radius: 10px;
        }
        p {
          position: absolute;
          bottom: -40%;
          padding-left: 10px;
          color: white;
          z-index: 2;
          text-align: center;
          width: 100%;
          font-family: "Raleway", sans-serif;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 145%; /* 29px */
          transition: opacity 1s ease-in-out;
        }
      }
      .hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
    .image-slider::before {
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      content: "";
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

@media screen and (max-width: "900px") {
  #service_area {
    margin-bottom: 80px;
    .sub_text {
      margin-bottom: 25px;
    }
    .solo {
      margin-top: 22px;
      h5 {
        font-size: 27px;
      }
    }
    .big,
    .little,
    .button {
      font-size: 19px;
    }
    .little {
      max-width: 550px;
    }
  }
}

@media screen and (max-width: "500px") {
  #service_area {
    margin-top: 30px;
    .sub_text {
      margin-top: 7px;
      padding: 0 10px;
    }
    .solo {
      h5 {
        margin-bottom: 10px;
        margin-top: 35px;
      }
      .showroom {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 15px;
        padding-bottom: 5px;
        padding-top: 2px;
        scroll-behavior: smooth;
        div {
          width: 220px;
          flex: none;
          p {
            font-size: 18px;
          }
        }
      }
      .showroom::-webkit-scrollbar {
        width: 0px;
        height: 5px;
      }
      .showroom::-webkit-scrollbar-track {
        background-color: rgb(145, 129, 129);
        border-radius: 100vw;
        margin-block: 0.2em;
      }
      .showroom::-webkit-scrollbar-thumb {
        background-color: rgb(58, 38, 68);
        border-radius: 100vw;
      }
      .showroom::-webkit-scrollbar-thumb:hover {
        background-color: rgb(44, 44, 73);
      }
    }
    .big{
      margin-top: 44px;
    }
    .big,
    .little,
    .button {
      font-size: 17px;
    }
    .little {
      max-width: 350px;
      margin: 15px auto 20px auto;
    }
    .button {
      padding: 8px 16px;
    }
  }
}
