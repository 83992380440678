#service_area {
  display: block;
  margin-bottom: 130px;
}
#service_area .sub_text {
  margin-bottom: 96px;
}
#service_area .big {
  color: #000;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-top: 32px;
  font-weight: 500;
  line-height: 145%; /* 29px */
}
#service_area .little {
  color: #1a1a1a;
  text-align: center;
  font-family: Raleway;
  margin: 15px auto 55px auto;
  font-size: 20px;
  max-width: 850px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 29px */
}
#service_area .button {
  border-radius: 8px;
  background: #e8c715;
  padding: 16px 32px;
  text-decoration: none;
  color: #141102;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 29px */
  transition: all 0.2s linear;
  cursor: pointer;
}
#service_area .button:hover {
  background: #f0c654;
}
#service_area .solo {
  margin-top: 64px;
}
#service_area .solo h5 {
  font-family: "Figtree", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 41.6px */
}
#service_area .solo .showroom {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  gap: 10px;
  padding-top: 32px;
}
#service_area .solo .showroom div {
  height: 162px;
  position: relative;
}
#service_area .solo .showroom div img {
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
  width: 100%;
  height: 162px;
  -o-object-fit: cover;
     object-fit: cover;
}
#service_area .solo .showroom div p {
  position: absolute;
  top: 40%;
  padding-left: 10px;
  color: white;
  z-index: 2;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 29px */
}
#service_area .solo .showroom div::before {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  content: "";
  background: rgba(0, 0, 0, 0.5);
}
#service_area .solo .image-slider {
  position: relative;
  width: 88%;
  height: 262px;
  overflow: hidden;
  margin: auto;
}
#service_area .solo .image-slider #slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 162px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s ease-in-out;
}
#service_area .solo .image-slider #slides img {
  width: 100%;
  height: 262px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
#service_area .solo .image-slider #slides p {
  position: absolute;
  bottom: -40%;
  padding-left: 10px;
  color: white;
  z-index: 2;
  text-align: center;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 29px */
  transition: opacity 1s ease-in-out;
}
#service_area .solo .image-slider .hidden {
  opacity: 0;
  pointer-events: none;
}
#service_area .solo .image-slider::before {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  content: "";
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 900px) {
  #service_area {
    margin-bottom: 80px;
  }
  #service_area .sub_text {
    margin-bottom: 25px;
  }
  #service_area .solo {
    margin-top: 22px;
  }
  #service_area .solo h5 {
    font-size: 27px;
  }
  #service_area .big,
  #service_area .little,
  #service_area .button {
    font-size: 19px;
  }
  #service_area .little {
    max-width: 550px;
  }
}
@media screen and (max-width: 500px) {
  #service_area {
    margin-top: 30px;
  }
  #service_area .sub_text {
    margin-top: 7px;
    padding: 0 10px;
  }
  #service_area .solo h5 {
    margin-bottom: 10px;
    margin-top: 35px;
  }
  #service_area .solo .showroom {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 15px;
    padding-bottom: 5px;
    padding-top: 2px;
    scroll-behavior: smooth;
  }
  #service_area .solo .showroom div {
    width: 220px;
    flex: none;
  }
  #service_area .solo .showroom div p {
    font-size: 18px;
  }
  #service_area .solo .showroom::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  #service_area .solo .showroom::-webkit-scrollbar-track {
    background-color: rgb(145, 129, 129);
    border-radius: 100vw;
    margin-block: 0.2em;
  }
  #service_area .solo .showroom::-webkit-scrollbar-thumb {
    background-color: rgb(58, 38, 68);
    border-radius: 100vw;
  }
  #service_area .solo .showroom::-webkit-scrollbar-thumb:hover {
    background-color: rgb(44, 44, 73);
  }
  #service_area .big {
    margin-top: 44px;
  }
  #service_area .big,
  #service_area .little,
  #service_area .button {
    font-size: 17px;
  }
  #service_area .little {
    max-width: 350px;
    margin: 15px auto 20px auto;
  }
  #service_area .button {
    padding: 8px 16px;
  }
}/*# sourceMappingURL=services.css.map */