@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

* {
  padding: 0 0;
  margin: 0 0;
  box-sizing: border-box;
}
#root {
  overflow-x: hidden;
}
.css-vubbuv{
  margin-right: 10px;
}
.App {
  width: 100vw;
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;
  .systemx {
    margin-bottom: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
    p {
      color: #000;
      text-align: center;
      font-family: "Figtree", sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 72.8px */
    }
    img {
      width: 40%;
    }
  }
  .one {
    height: 900px;
    position: relative;
    width: 100%;
    max-width: 1440px;
    // overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.78);
      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 5%;
        .hamburger {
          display: none;
          z-index: 3;
          margin-right: 3%;
          cursor: pointer;
          width: 30px;
          opacity: 1;
          height: 40px;
          background-color: inherit;
          border-radius: 12.25px;
          #nav-icon4 {
            width: 60px;
            height: 35px;
            background-color: inherit;
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            transform: scale(0.5);
            cursor: pointer;
            span {
              pointer-events: none;
            }
          }

          #nav-icon4 span {
            display: block;
            position: absolute;
            height: 6px;
            width: 100%;
            background-color: white;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg) scale(0.5);
            -moz-transform: rotate(0deg) scale(0.5);
            -o-transform: rotate(0deg) scale(0.5);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
          }

          #nav-icon4 span:nth-child(1) {
            top: 0px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          #nav-icon4 span:nth-child(2) {
            top: 18px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          #nav-icon4 span:nth-child(3) {
            top: 36px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          #nav-icon4.open span:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -3px;
            left: 8px;
          }

          #nav-icon4.open span:nth-child(2) {
            width: 0%;
            opacity: 0;
          }

          #nav-icon4.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 39px;
            left: 8px;
          }
        }
        .hamburger:hover {
          opacity: 0.65;
        }
        img {
          object-fit: contain;
          width: 84px;
          height: 67px;
        }
        nav {
          display: flex;
          justify-content: space-between;
          width: 69%;
          align-items: center;
          font-family: "Raleway", sans-serif;
          font-size: 18px;
          font-weight: 400;
          section {
            border-radius: 8px;
            justify-content: space-between;
            width: 70%;
            max-width: 530px;
            background: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(34px);
            display: flex;
            // gap: 1.2;
            color: white;
            padding: 20px 16px;
            a {
              cursor: pointer;
              position: relative;
              &::after {
                position: absolute;
                border-top: 0.15em solid #e8c715;
                border-radius: 1rem;
                content: "";
                right: 100%;
                left: 0;
                bottom: -0.46em;
                transition: right 0.25s ease;
              }
              &:hover::after {
                right: 40%;
              }
            }
          }
          .contact_us {
            border-radius: 8px;
            border: 1px solid #e8c715;
            background: #e8c715;
            padding: 16px 32px;
            transition: all 0.2s linear;
            cursor: pointer;
            &:hover {
              background: #f0c654;
            }
          }
        }
      }
      article {
        margin-top: 175px;
        font-family: "Raleway", sans-serif;
        h2 {
          font-size: 64px;
          font-weight: 800;
          margin-bottom: 30px;
          text-align: center;
          max-width: 1040px;
          margin: auto;
          opacity: 0;
          animation: pop 1s linear 1s 1 forwards;
          letter-spacing: -0.65px;
          color: white;
          span {
            color: #e8c715;
          }
        }
        p {
          font-size: 24px;
          font-weight: 400;
          text-align: center;
        }
        p {
          color: white;
          margin: 10px 0 0 0;
          font-size: 19px;
          opacity: 1;
          letter-spacing: -0.35px;
          text-align: center;
          overflow-y: hidden;
          height: 3.5vh;
          margin: 30px auto 60px;
          font-family: "Raleway", sans-serif;
          max-width: 800px;
          // margin-bottom: 3vh;
          transform: scale(0.94);
          animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
          span {
            margin-right: 5px;
            opacity: 0;
            filter: blur(4px);
          }

          :nth-child(1) {
            animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(2) {
            animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(3) {
            animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(4) {
            animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(5) {
            animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(6) {
            animation: fade-in 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(7) {
            animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(8) {
            animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(9) {
            animation: fade-in 0.8s 2.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(10) {
            animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }

          :nth-child(11) {
            animation: fade-in 0.8s 3.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
          }
        }
        .button {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          border-radius: 8px;
          color: black;
          text-decoration: none;
          margin-top: 40px;
          font-family: "Raleway", sans-serif;
          background: #e8c715;
          padding: 16px 32px;
          line-height: 145%; /* 29px */
          transition: all 0.2s linear;
          border: none;
          opacity: 0;
          animation: pop 1s linear 1s 1 forwards;
          cursor: pointer;
          &:hover {
            background: #f0c654;
          }
        }
      }
    }
  }
  .two,
  .three,
  .four {
    text-align: center;
    padding-top: 120px;
    margin: 0 55px;
    position: relative;
    right: 8px;
    .head {
      color: #403a24;
      font-family: "Raleway", sans-serif;
      font-size: 20px;
      font-weight: 400;
      border-radius: 16px;
      background: #f7e08c;
      margin-bottom: 20px;
      padding: 10px 20px;
      line-height: 145%; /* 29px */
    }
    h2 {
      color: #000;
      text-align: center;
      font-family: "Figtree", sans-serif;
      font-size: 56px;
      font-style: normal;
      margin-top: 30px;
      font-weight: 700;
      line-height: 130%; /* 72.8px */
    }
    .sub_text {
      color: #6b6b6b;
      text-align: center;
      font-family: "Raleway", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%; /* 29px */
    }
    section {
      margin: 88px 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      grid-gap: 1.5rem;
      grid-row-gap: 1.5rem;
      row-gap: 1.5rem;
      grid-column-gap: 1.5rem;
      gap: 10px;
      div {
        position: relative;
        height: 500px;
        img {
          object-fit: cover;
          width: -webkit-fill-available;
          height: 500px;
          position: relative;
        }
        p {
          position: absolute;
          bottom: 50px;
          color: white;
          font-size: 36px;
          font-family: "Raleway", sans-serif;
          font-weight: 600;
          z-index: 3;
          text-align: center;
          width: -webkit-fill-available;
        }
      }
      div::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        content: "";
        background: rgba(0, 0, 0, 0.3);
      }
    }
    .mobile {
      display: none;
    }
    .info {
      margin-bottom: 83px;
      font-family: "Figtree", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
      span {
        color: black;
        margin-right: 5px;
      }
      a {
        color: #e0bf0b;
        cursor: pointer;
        transition: opacity 0.3s linear;
        &:hover {
          opacity: 0.56;
        }
      }
    }
  }
  .three {
    padding-top: 0;
    width: 85%;
    margin: auto;
    article{
      display: grid;
      margin: 80px 0;
      gap: 18px; 
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr 1fr; 
      .comments{
        background: #f2f2f2;
        padding: 15px 15px 50px;
        border-radius: 8px;
        position: relative;
        div{
          display: flex;
          justify-content: space-between;
          p{
            span{
              margin-right: 4px;
              .MuiSvgIcon-root{
                width:15px ;
                height:15px ;
                color: orange;
              }
            }
          }
          img{
            object-fit: contain;
            width: 18px;
            height: 18px;

          }
        }
        .text{
          margin: 25px 0;
          text-align: left;
          font-size: 16px;
          color: #6b6b6b;
          line-height: 145%;
          font-family: 'Raleway',sans-serif;
        }
        .name{
          position: absolute;
          bottom: 40px;
          left: 15px;
          font-family: 'Raleway',sans-serif;
        }
      }
    }
  }
  .form {
    position: relative;
    background: #fffdf2;
    padding: 100px 0px;
    .right {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 180px;
    }
    .left {
      position: absolute;
      width: 190px;
      bottom: 0px;
      left: 0px;
    }
    .parent {
      background-color: white;
      position: relative;
      margin: auto;
      width: 85%;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      article {
        padding: 48px 34px;
        h1 {
          font-family: "Figtree", sans-serif;
          font-size: 56px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%; /* 72.8px */
        }
        p {
          max-width: 460px;
          font-family: 'Raleway',sans-serif;
          color: #6b6b6b
        }
        a {
          font-family: "Raleway", sans-serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          color: black;
          margin: 20px 0;
          display: flex;
          align-items: center;
          line-height: 145%; /* 29px */
          span {
            font-family: "Raleway", sans-serif;
            font-size: 14px;
            font-style: normal;
            text-decoration: underline;
            margin-left: 5px;
            font-weight: 400;
            line-height: 145%; /* 23.2px */
            text-decoration-line: underline;
          }
          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }
        h5 {
          margin-top: 40px;
          color: #000;
          font-family: "Figtree", sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 41.6px */
        }
      }
      aside {
        width: 40%;
        height: -webkit-fill-available;
        margin-right: 38px;
        div {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          position: relative;
          section {
            position: absolute;
            display: none;
            background-color: #f8f8f8;
            z-index: 2;
            width: -webkit-fill-available;
            bottom: -212px;
            border-radius: 5px;
            left: 0;
            p {
              margin: 12px 0px;
              padding-left: 16px;
              font-family: "Raleway", sans-serif;
              font-size: 14px;
              font-style: normal;
              border: none;
              cursor: pointer;
              transition: all 0.2s linear;
              &:hover {
                opacity: 0.55;
              }
            }
          }
          span {
            color: #000;
            font-family: "Raleway", sans-serif;
            font-size: 14px;
            font-style: normal;
            margin: 5px 0;
            font-weight: 400;
            line-height: 145%; /* 23.2px */
          }
          input,
          .services {
            outline: none;
            background-color: #f2f2f2;
            height: 48px;
            font-family: "Raleway", sans-serif;
            font-size: 14px;
            font-style: normal;
            border: none;
            padding: 0 16px;
          }
          .services {
            display: flex;
            padding: 0px 16px 0px 0px;
            align-items: center;
            justify-content: space-between;
            .arrow {
              transition: all 0.2s linear;
              will-change: transform;
              -webkit-transition: all 0.2s linear;
              -ms-transition: all 0.2s linear;
              cursor: pointer;
              &:hover {
                opacity: 0.57;
              }
              .css-i4bv87-MuiSvgIcon-root {
                pointer-events: none;
              }
            }
          }
          #message {
            height: 160px;
            outline: none;
            background-color: #f2f2f2;
            resize: none;
            padding: 12px 16px;
            font-family: "Raleway", sans-serif;
            font-size: 14px;
            font-style: normal;
            border: none;
          }
        }
        button {
          margin-top: 28px;
          margin-bottom: 22px;
          color: black;
          width: -webkit-fill-available;
          background: #e8c715;
          padding: 12px 0px;
          font-family: "Raleway", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          transition: all 0.4s linear;
          border: none;
          cursor: pointer;
          &:hover {
            background: #f0c654;
            color: rgb(87, 87, 87);
          }
        }
      }
    }
  }
  .sub {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 85%;
    margin: auto;
    // position: relative;
    // right: -11px;
    padding: 100px 0px;
    justify-content: space-between;
    aside {
      // margin-right: 40px;
      h4 {
        color: var(--primary-black, #141414);
        font-family: "Figtree", sans-serif;
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 32px;
        line-height: 130%; /* 72.8px */
      }
      p {
        color: #000;
        font-family: "Raleway", sans-serif;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        max-width: 530px;
        line-height: 145%; /* 29px */
      }
    }
    img {
      object-fit: contain;
      max-width: 600px;
      min-width: 300px;
      width: 45%;
      flex: 1;
    }
  }
  footer {
    background-color: #171506;
    a {
      color: black;
      display: flex;
      text-decoration-color:#736928; ;
      
    }
    div {
      display: flex;
    }
    .main {
      padding: 95px 60px 70px;
      section {
        display: flex;
        margin-left: 80px;
        width: 70%;
        justify-content: space-between;
        min-width: 800px;
        align-items: flex-start;

        div {
          display: flex;
          flex-direction: column;
          h6 {
            font-size: 20px;
            color: #99891E;
            margin-bottom: 10px;
            font-family: 'Raleway',sans-serif;
            font-weight: 600;
          }
          .touch {
            position: relative;
            bottom: 10px;
          }
          p {
            max-width: 300px;
            margin-bottom: 10px;
            font-family: 'Raleway',sans-serif;
            display: flex;
            color: #736928;

          }
          span {
            margin-bottom: 6px;
            white-space: nowrap;
            font-family: 'Raleway',sans-serif;
            color: #736928;

          }
          .css-i4bv87-MuiSvgIcon-root {
            color: white;
            margin-right: 10px;
          }
          .css-vubbuv{
            margin-right: 10px;
            color: white;
          }
        }
      }
    }
    .sub_p {
      justify-content: space-between;
      img {
        width: 38%;
      }
      nav {
        white-space: nowrap;
        display: flex;
        a {
          margin-right: 10px;
          img {
            width: fit-content;
          }
        }
      }
    }
  }
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes pop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: "1065px") {
  .App {
    .sub {
      justify-content: center;
      padding: 50px;
      aside {
        margin-bottom: 42px;
        h4 {
          text-align: center;
        }
        p {
          max-width: -webkit-fill-available;
          text-align: center;
        }
      }
    }
    footer {
      .main {
        flex-direction: column;
        .logo {
          width: 100px;
          height: 140px;
        }
        section {
          flex-direction: column;
          margin-left: 0px;
          div {
            p {
              max-width: 500px;
            }
          }
          h6 {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: "900px") {
  .App {
    .systemx {
      margin-bottom: 60px;
      flex-direction: column;
      p {
        font-size: 25px;
        margin-bottom: 20px;
      }
      img{
        width: 50%;
      }
    }
    .one {
      height: 650px;
      div {
        article {
          margin-top: 100px;
          h2 {
            font-size: 48px;
            max-width: 550px;
          }
          p {
            font-size: 16px;
            margin: 15px auto 50px;
          }
          .button {
            font-size: 18px;
          }
        }
        header {
          position: relative;
          .hamburger {
            display: block;
          }
          nav {
            display: none;
            z-index: 1;
            flex-direction: column;
            position: absolute;
            right: 0;
            padding: 50px 0;
            width: 40vw;
            background: rgba(0, 0, 0, 0.75);
            border-radius: 10px;
            top: 0;
            transition: all 0.3s linear;
            section {
              flex-direction: column;
              min-width: 200px;
              background: inherit;
              width: 40vw;
              a {
                width: fit-content;
                margin-bottom: 35px;
              }
            }
          }
        }
      }
    }
    .two,
    .three,
    .four {
      padding-top: 20px;
      .head {
        font-size: 18px;
        padding: 8px 16px;
      }
      .info{
        margin-bottom: 50px;
      }
      h2 {
        font-size: 40px;
        margin-top: 20px;
      }
      .sub_text {
        font-size: 18px;
      }
      section {
        margin: 50px 0;
        div {
          height: 300px;
          img {
            height: 300px;
          }
          p {
            font-size: 24px;
          }
        }
      }
    }
    .three{
      article{
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
    }
    .form {
      padding: 50px 0;
      .parent {
        flex-direction: column;
        align-items: flex-start;
        article {
          a,
          p {
            max-width: -webkit-fill-available;
            font-size: 18px;
          }
          h1 {
            font-size: 40px;
          }
          h5 {
            font-size: 23px;
            margin-top: 25px;
          }
        }
        aside {
          width: -webkit-fill-available;
          margin: 0 32px;
          margin-bottom: 48px;
        }
      }
    }
    .sub {
      aside {
        h4 {
          font-size: 40px;
          margin-bottom: 12px;
        }
        p {
          font-size: 18px;
        }
      }
      img {
        width: -webkit-fill-available;
      }
    }
    footer {
      .main {
        padding: 40px;
        section {
          div {
            .touch {
              bottom: 0px;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: "500px") {
  .App {

    .systemx {
      margin: 40px auto 70px;
      p {
        font-size: 20px;
      }
      img {
        width: 43%;
      }
    }
    .one {
      height: 550px;
      div {
        header {
          nav {
            width: 55vw;
            height: 100vh;
            border-radius: 0px;
            z-index: 2;
            min-width: 200px;
            justify-content: flex-start;
            align-items: baseline;
            background: rgba(0, 0, 0, 0.75);
            section {
              background: none;
              backdrop-filter: none;
              a {
                font-size: 16px;
              }
            }
            .contact_us {
              font-size: 16px;
              padding: 8px 20px;
              margin-left: 16px;
            }
          }
          .hamburger {
            margin-right: 5.5vw;
          }
          img {
            width: 64px;
            height: 47px;
            margin-top: 10px;
          }
        }
        article {
          h2 {
            font-size: 30px;
            max-width: 300px;
            line-height: 40px;
          }
          p {
            max-width: 300px;
            overflow: visible;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 50px;
            word-wrap: break-word;
          }
          .button {
            font-size: 16px;
            margin-top: 20px;
            padding: 8px 16px;
          }
        }
      }
    }
    .two,
    .three,
    .four {
      padding-top: 40px;
      margin: 0 10px;
      .head {
        font-size: 16px;
        margin-bottom: 10px;
        padding: 5px 10px;
      }
      h2 {
        font-size: 32px;
        margin-top: 15px;
      }
      .sub_text {
        font-size: 16px;
      }
      section {
        margin: 25px 0.3vw;
        height: 300px;
      }
      .desktop {
        display: none;
      }
      .mobile {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
        margin: 30px 0.2vw;
        width: 100vw;
        max-width: 1440px;
        // padding: 0 30px;
        overflow: hidden;
        section {
          display: flex;
          animation: h-scroll 11s linear infinite;
          will-change: transform;
          margin: 0;
          padding: 0 5px;
          div{
            width: 246px;
            img{
              width: 246px;
            }
          }
        }
      }
      .info {
        font-size: 16px;
        margin-bottom: 30px;
      }
      .mobile::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100%;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0),
          rgb(33, 32, 32)
        );
      }
      .mobile::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: 100%;
        z-index: 2;
        content: "";
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgb(33, 32, 32)
        );
      }
    }
    .three{
      width: 100%;
      padding: 0 20px;
      margin-top:60px;
      .sub_text{
        padding-top:15px
      }
      .head{
        margin-top:15px;
      }
      h2{
        margin-top:20px
      }
      article{
        display: flex;
        width: 85%;
        overflow: hidden;
        min-height:360px;
        margin:40px auto;
        .comments{
          opacity: 0;
          transition: opacity 1s linear;
          width:100%;
        }
        .active{
          opacity:1;
        }
      }
    }
    .sub {
      right: 10px;
      margin-top: 40px;
      padding: 0px;
      aside {
        h4 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .form {
      .right,
      .left {
        width: 120px;
      }
      .parent {
        article {
          padding: 48px 24px;
          h1 {
            text-align: center;
            font-size: 32px;
            margin-bottom: 7px;
          }
          a,
          p {
            font-size: 16px;
            text-align: center;
          }
          h5 {
            font-size: 20px;
          }
          button {
            font-size: 16px;
            margin-bottom: 0px;
          }
        }
        aside {
          margin: 0 22px;
          button {
            font-size: 18px;
          }
        }
      }
    }
    footer {
      .main {
        padding: 0px 18px 30px;
        section {
          div {
            #about {
              margin-top: 0px;
            }
            p {
              max-width: 300px;
              .MuiSvgIcon-root {
                color: white;
                margin-right:9px;
              }
            }
            a{
              .MuiSvgIcon-root{
                color: white;
                margin-right: 9px;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes h-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
